import React from 'react'
import styled from '@emotion/styled'
import * as routes from '../../../constants/routes'

import './style.scss'
import Uploader from 'components/shared/Forms/Uploader'
import LoadingDots from 'components/shared/Forms/LoadingDots'
import { button } from 'utils/styles'

const UploadBtn = styled('button')`
  display: flex;
  flex: 1;
  ${button.purple};
  ${button.big};
`

const INITIAL_STATE = {
  error: '',
  loading: false,
  user_img: '',
}

export default class AddUserPhotoForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  onSubmit = event => {
    event.preventDefault()
    this.setState({ loading: true })
    const { user_img } = this.state
    ;(async () => {
      let error = await this.props.onSubmit(user_img)
      if (error) {
        this.setState({ error, loading: false })
      }
    })()
  }

  render() {
    const { user_img, loading } = this.state

    return (
      <form
        action=""
        method="POST"
        role="login-form"
        className="signup-form"
        id="login-form"
        onSubmit={this.onSubmit}
      >
        <section
          className="form-page-container"
          style={{ textAlign: 'center' }}
        >
          <div>
            <div className="hint-text" style={{ marginBottom: 20 }}>
              This helps lenders and renters know that you are a real person
            </div>
            <div>
              {user_img && <img src={user_img} height="100" width="100" />}
              <Uploader
                id="file"
                name="file"
                onUploadComplete={info =>
                  this.setState({ user_img: info.cdnUrl })
                }
              />
            </div>
            <div className="bottom-btn-container step-1">
              <div>
                <UploadBtn>Continue</UploadBtn>
              </div>
            </div>
            <div className="loading-animation">
              <LoadingDots loading={loading} />
            </div>
          </div>
        </section>
      </form>
    )
  }
}
