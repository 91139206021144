import React from 'react'
import Link from 'gatsby-link'
import { navigate } from 'gatsby'

import './auth.scss'
import * as routes from 'constants/routes'
import AddUserPhotoForm from 'components/Auth/SignUp/AddUserPhoto'
import Layout from 'layouts/AuthenticatedPage'
import SEO from 'components/SEO/SEO'

export class AddPhotoPage extends React.Component {
  getRedirectURL = () => {
    let state,
      redirectURL = routes.SEARCH
    if ((state = this.props.location.state)) {
      redirectURL = state.redirectURL || redirectURL
    }
    return redirectURL
  }

  onSubmit = async user_img => {
    try {
      let user = this.props.currentUser
      await user.updateProfile({ photoURL: user_img })

      await this.props.database.addUserPhoto(user_img)

      navigate(this.getRedirectURL(), {
        replace: true,
        state: { ...(this.props.location.state || {}) },
      })
    } catch (error) {
      return error
    }
  }

  render() {
    return (
      <div id="add-photo-page">
        <div className="content-wrap">
          <section className="page-title form-title">
            <h2>Add profile picture</h2>
          </section>
          <AddUserPhotoForm onSubmit={this.onSubmit} />
        </div>
      </div>
    )
  }
}

export default ({ location }) => (
  <Layout location={location} displayFooter={false} showBeacon={true}>
    <SEO title="Add photo" />
    <AddPhotoPage />
  </Layout>
)
